/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Sora_100Thin = require('./Sora_100Thin.ttf');
export const Sora_200ExtraLight = require('./Sora_200ExtraLight.ttf');
export const Sora_300Light = require('./Sora_300Light.ttf');
export const Sora_400Regular = require('./Sora_400Regular.ttf');
export const Sora_500Medium = require('./Sora_500Medium.ttf');
export const Sora_600SemiBold = require('./Sora_600SemiBold.ttf');
export const Sora_700Bold = require('./Sora_700Bold.ttf');
export const Sora_800ExtraBold = require('./Sora_800ExtraBold.ttf');
